import { Card, CardContent, CardHeader } from '@material-ui/core';
import CardWithIcon from './CardWithIcon';
import BrandCounts from './brandCount';
import UserCounts from './userCount';
import dataProvider from './dataProvider';
import { apiUrl } from './Constants';

import React, {
    useState,
    useEffect,
    useCallback,
    FC,
    CSSProperties,
} from 'react';

const styles = {
    flex: { display: 'flex', marginTop: '10px' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const brandData = [
    {
        brand: 'Test1', count: '1'
    },
    {
        brand: 'Test1', count: '1'
    }
]

function Dashboard() {
    const [count, setCount] = useState({ c: 0 });
    const [brandData, setBrand] = useState([]);

    useEffect(() => {
        let fetched = true;

        const url = `${apiUrl}/notifyme_usercount`;
        fetch(url).then(res=>res.json()).then((response) => {
            if (fetched) {
                setCount(response);
            }               
        });        

        return () => fetched = false;
    }, []);

    useEffect(() => {
        let fetched = true;

        const url = `${apiUrl}/notifyme_reports/brandcount`;
        fetch(url).then(res=>res.json()).then((response) => {
            if (fetched) {
                setBrand(response);
            }               
        });        

        return () => fetched = false;
    }, []);

    return (
        <div>
            <Card>
                <CardHeader title="Welcome to Notify Me Info Panel" />
                <CardContent>Live count of Notify Me data</CardContent>
            </Card>
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <BrandCounts brands={brandData} />
                    </div>
                </div>
                <div style={styles.rightCol}>
                    <div style={styles.flex}>
                        {/* <CardWithIcon /> */}
                        <UserCounts value={count} />
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
    );
};

export default Dashboard;



