import { Filter, List, Datagrid, TextField, TextInput, EmailField, UrlField, ImageField, DateField, SimpleForm, ReferenceInput, SelectInput, Create, Edit, BooleanField, BooleanInput,
        AutocompleteInput, required} from 'react-admin';

import * as React from 'react';
import { cloneElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Button,
    sanitizeListRestProps,
    DateInput
} from 'react-admin';
import IconEvent from '@material-ui/icons/Event';

const NotifyFilter = props => (    
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn />
            <DateInput source="date_gte" label='Passed Since' alwaysOn />
            <DateInput source="date_lte" label='Passed Before' alwaysOn />                          
        </Filter>    
);

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}   
            <CreateButton />             
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={100000}
            />            
        </TopToolbar>
    );
};

export const NotifyList = props => (
    <List filters={<NotifyFilter />} actions={<ListActions />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="customer_id" />
            <EmailField source="customer_email" />
            <TextField source="brand" />
            <ImageField source="image" />
            <TextField source="title" />
            <TextField source="product_id" />
            <BooleanField source="subscribed" />
            <DateField source="createdAt" />            
        </Datagrid>
    </List>
);

const customerOptionRenderer = customer => `${customer.firstName} ${customer.lastName} - ${customer.email}`;
const productOptionRenderer = product => `${product.title} (${product.id})`;

export const WishlistCreate = props => (    
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput validate={[required()]} source="customer_id" reference="customers">
                <AutocompleteInput optionText={customerOptionRenderer} />
            </ReferenceInput>
            <ReferenceInput validate={[required()]} source="product_id" reference="products">
                <AutocompleteInput optionText={productOptionRenderer} />
            </ReferenceInput>
            <BooleanInput source="subscribed" />            
        </SimpleForm>
    </Create>
);

export const WishlistEdit = props => (
    <Edit {...props}>
        <SimpleForm>            
            <DateField source="createdAt" /> 
            <TextField disabled source="customer_id" label="Customer ID"/>
            <EmailField disabled source="customer_email" />
            <TextField source="brand" />
            <TextField source="title" />
            <ImageField source="image" />            
            <TextField source="product_id" label="Product ID"/>                        
            <BooleanInput label="Subscribed to bag notification" source="subscribed" />
        </SimpleForm>
    </Edit>
);