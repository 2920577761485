import { Admin, Resource, ListGuesser, EditGuesser } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import { UserList, UserCreate, UserEdit } from './users';
import { NotifyList, WishlistCreate, WishlistEdit} from './notifications';
import {PostList, PostEdit, PostCreate} from './posts';
import Dashboard from './Dashboard';
import authProvider from './authProvider';

// const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');
import dataProvider from './dataProvider';
const App = () => (
  <Admin dashboard={Dashboard} dataProvider={dataProvider} authProvider={authProvider}>    
    <Resource name="users" list={UserList} create={UserCreate} edit={UserEdit}/>
    <Resource name="customers" />
    <Resource name="products" />
    <Resource name="notifications" list={NotifyList} create={WishlistCreate} edit={WishlistEdit}/>
  </Admin>
);

export default App;

// import * as React from "react";
// import { Admin, Resource, ListGuesser } from 'react-admin';
// import { PostList, PostEdit, PostCreate } from './posts';
// import jsonServerProvider from 'ra-data-json-server';

// const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');
// const App = () => (
//     <Admin dataProvider={dataProvider}>
//         <Resource name="posts" list={PostList} edit={PostEdit} create={PostCreate} />
//         <Resource name="users" list={ListGuesser} />
//     </Admin>
// );

// export default App;