import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { apiUrl } from './Constants';

const httpClient = fetchUtils.fetchJson;

export default {
    getList: (resource, params) => {
        console.log('getList code in dataProvider');
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };

        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        let options = {};
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json' });
        }

        const { token } = JSON.parse(localStorage.getItem('auth'));
        options.headers.set('x-access-token', `${token}`);

        return httpClient(url, options).then(
            ({ headers, json }) => ({
                data: json.data,
                total: json.total,
            })
        );
    },

    getOne: (resource, params) => {

        let options = {};
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json' });
        }

        const { token } = JSON.parse(localStorage.getItem('auth'));
        options.headers.set('x-access-token', `${token}`);

        return httpClient(`${apiUrl}/${resource}/${params.id}`, options).then(({ json }) => ({
            data: json,
        }))
    },

    getMany: (resource, params) => {
        console.log('getMany code in dataProvider');
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };

        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        let adminResource = false;
        let adminResources = ['users', 'customers', 'products'];

        let options = {};
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json' });
        }

        if (adminResources.includes(resource.trim())) {
            adminResource = true;

            const { token } = JSON.parse(localStorage.getItem('auth'));

            if (!token) throw new Error('Not Authenticated');
            options.headers.set('x-access-token', `${token}`);
        }

        // return httpClient(adminResource ? adminUrl : url, options).then(({ json }) => ({ data: json }));
        return httpClient(url, options).then(
            ({ headers, json }) => ({
                data: json.data,
                total: json.total,
            })
        );
    },

    getManyReference: (resource, params) => {
        console.log('getManyReference code in dataProvider');
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: (resource, params) => {

        let headers = new Headers({ Accept: 'application/json' });
        const { token } = JSON.parse(localStorage.getItem('auth'));

        if (!token) throw new Error('Not Authenticated');
        else headers.set('x-access-token', `${token}`);        

        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
            headers: headers,
        }).then(({ json }) => ({ data: json }));
    },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }));
    }
};