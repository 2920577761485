import { List, Datagrid, TextField, EmailField, UrlField, TextInput, Create, Edit, SimpleForm, required, email, PasswordInput, DateField } from 'react-admin';

export const UserList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <EmailField source="email" />
            <DateField source="createdAt" />
        </Datagrid>
    </List>
);

export const UserCreate = props => (    
    <Create {...props}>
        <SimpleForm>
            <TextInput source="firstName" validate={[required()]}/>
            <TextInput source="lastName" validate={[required()]} />
            <TextInput source="email" validate={[required(), email()]} />            
            <PasswordInput source="password" initiallyVisible validate={[required()]} />
        </SimpleForm>
    </Create>
);

export const UserEdit= props => (    
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="firstName" validate={[required()]}/>
            <TextInput source="lastName" validate={[required()]} />
            <TextInput source="email" validate={[required(), email()]} />            
            <PasswordInput source="password" />
        </SimpleForm>
    </Edit>
);