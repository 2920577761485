import { authUrl, loginUrl } from './Constants';
import { fetchUtils } from 'react-admin';

const httpClient = fetchUtils.fetchJson;

export default {
    // called when the user attempts to log in
    login: ({ username, password }) => {
        let email = username;
        const request = new Request(loginUrl, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('auth', JSON.stringify(auth));
            })
            .catch((error) => {
                throw new Error('Network error')
            });

        // if (            
        //     (username=='jo' && password=='CocoonNotifyMe2021')
        //     || (username=='ceanne' && password=="bK#zb7K!N5g7gz@x") 
        //     || (username=='amy' && password=="4AWAgYB%Ak_b+3s-")
        //     || (username=='riaan' && password=="3X_L_EnGS5Lse8aV")
        //     ) {
        //     localStorage.setItem('username', username);
        //     return Promise.resolve();
        // } else return Promise.reject();
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        try {
            let localUser = JSON.parse(localStorage.getItem('auth'));    
            return localUser.token!=undefined && localUser.token ? Promise.resolve() : Promise.reject();
        } catch (error) {
            console.log('Error in checkAuth:');
            console.log(error);
            return Promise.reject(error);
        }        
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};