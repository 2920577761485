import * as React from 'react';
import { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import CardWithIcon from './CardWithIcon';
import DollarIcon from '@material-ui/icons/People';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
}));

const UserCounts = ({value}) => {    
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/commands"
            icon={DollarIcon}
            title="Number of unique users in Notify Me Table"
            subtitle={value.c}
        />
    );
};

export default UserCounts;
